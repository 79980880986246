<template>
  <div
      :class="['select-container', { block }]"
      :style="{
      maxWidth: maxWidth,
    }"
  >
    <div
        @click="!isDisabled ? toggleList() : null"
    >

      <p class="title-label">
        <slot></slot>
      </p>

      <div class="input"
           :class="{ 'select-disabled': isDisabled }"
      >
        {{ modelValue }}

        <img src="../assets/images/arrows.svg" class="icon">
      </div>
    </div>

    <div v-show="showList" class="list-container">
      <ul id="list" class="list">
        <li
            class="list-item"
            @click="selectItem(item)"
            v-for="item in list"
            :key="item.abbreviation"
        >
          <span>
            {{ item.name }}
          </span>
        </li>
      </ul>
    </div>

    <p class="input-error" v-if="error && error.$error">{{ errorText }}</p>
  </div>
</template>

<script>
import Input from '@/components/Input';

export default {
  name: 'Select',
  emits: ['update:modelValue', 'select-id', 'select-item'],
  components: {Input},
  props: {
    error: {
      type: Object,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    maxWidth: {
      type: String,
    },
    list: {
      type: Array,
    },
    modelValue: {
      type: [String, Number],
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showList: false,
    }
  },
  beforeMount() {
    document.addEventListener('click', this.detectClickOutside);
  },
  unmounted() {
    document.removeEventListener('click', this.detectClickOutside)
  },
  methods: {
    selectItem(item) {
      this.$emit('update:modelValue', item.name)
      this.$emit('select-id', item.abbreviation)
      this.$emit('select-item', item)
      this.closeList()
    },
    toggleList() {
      this.showList = true;
    },
    closeList() {
      if (this.showList) {
        this.showList = false
      }
    },
    detectClickOutside(event) {
      if (!(this.$el == event.target || this.$el.contains(event.target))) {
        this.closeList();
      }
    }
  },
  computed: {
    errorText() {
      return this.error && this.error.$errors.length > 0 ? this.error.$errors[0].$message : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
  width: 100%;
  padding: 8px 0;

  .input {
    position: relative;
    height: 55px;
    width: 100%;
    padding: 18px 15px;
    font-size: 16px;
    font-weight: 500;
    color: #313131;
    background: rgba(194, 202, 207, 0.2);
    border: 1px solid $blue;
    border-radius: 3px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      height: 50px;
      padding: 16px 13px;
      font-size: 13px;
    }
  }

  .input-error {
    font-size: 13px;
    color: $red;
  }

  .list-container {
    position: absolute;
    z-index: 1;
    margin-top: -1px;
    width: 100%;
    max-height: 190px;
    overflow: auto;
    background: #fff;
    border: 2px solid $blue;
    border-radius: 5px;
    cursor: pointer;
  }

  .select-disabled {
    cursor: not-allowed;
    background: #ddd;
  }

  &.block {
    .list-container {
      max-width: none;
    }
  }

  .list {
    padding: 0px;
    font-size: 17px;

    &-item {
      text-align: center;

      span {
        display: block;
        width: 100%;
        padding: 8px 10px
      }

      &:hover {
        background: rgba($blue, .1);
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }

  .title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 33px;
    color: $blue-darker;

    span {
      color: $red;
      font-size: 20px;
    }

    @media screen and (max-width: 768px) {
      font-size: 13px;

      span {
        color: $red;
        font-size: 17px;
      }
    }
  }
}
</style>
